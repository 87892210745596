//=require ../../../../node_modules/vanilla-cookieconsent/dist/cookieconsent.js

// obtain plugin

var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: ACTIVE_LOCALE,
    autoclear_cookies: true,                   // default: false
    //theme_css: '/global/libs/cookie-consent/cookieconsent.css',  // 🚨 replace with a valid path
    page_scripts: true,                        // default: false
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false,             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 30,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'zoom'            // zoom/slide
        }
    },


    onFirstAction: function (user_preferences, cookie) {
    },

    onAccept: function (cookie) {
    },

    onChange: function (cookie, changed_preferences) {
    },

    languages: {
        /*'en': {
            consent_modal: {
                title: 'Privacy settings',
                description: 'We use cookies on our websites. Some are essential, while others help us improve the site and your user experience. Do you agree to the use of all cookies? <a class="cc-link" data-cc="c-settings">Detailed settings</a>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'For'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        description: 'We use cookies to ensure the basic functions of the website and to improve your online experience. For each category, you can choose to log in / out whenever you want.'
                    }, {
                        title: 'Necessary and technical cookies',
                        description: 'These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly. ',
                        toggle: {
                            value: 'basic',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Enable functional cookies',
                        description: 'These cookies collect data designed to remember users\' choices so that we can provide a better and more personalized experience. ',
                        toggle: {
                            value: 'function',
                            enabled: false,
                            readonly: false         // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Enable analytics cookies',
                        description: 'These cookies help us to understand the behavior of visitors to our website, discover errors and provide better overall analytics.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [// list of all expected cookies
                            {
                                col1: '^__utm',       // match all cookies starting with "_ut"
                                col2: 'localhost',
                                col3: 'Google Analytics',
                                col4: 'More information about Cookies Google Analytics is <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs_-_cookie_usage" target="_blank">here</a>',
                                is_regex: true
                            },
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description..',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            },
                            {
                                col1: '^SL_C',
                                col2: 'smartlook.com',
                                col3: '1 month',
                                col4: 'description ...',
                                is_regex: true
                            }

                        ]
                    }, {
                        title: 'Enable marketing cookies',
                        description: 'These cookies are used to monitor the effectiveness of advertising campaigns so that we can provide more relevant services.',
                        toggle: {
                            value: 'marketing',     // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    }
                ]
            }
        },*/
        'cs': {
            consent_modal: {
                title: 'Nastavení soukromí',
                description: 'Na našich webových stránkách používáme soubory cookies. Některé z nich jsou nezbytné, zatímco jiné nám pomáhají vylepšit web a Váš uživatelský zážitek. Souhlasíte s použitím všech cookies? <a class="cc-link" data-cc="c-settings">Podrobné nastavení</a>',
                primary_btn: {
                    text: 'Povolit vše',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Nechte mě vybrat',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Pokročilá nastavení cookies',
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Povolit vše',
                //reject_all_btn: 'Odmítnout vše',
                close_btn_label: 'Zavřít',
                cookie_table_headers: [
                    {col1: 'Název'},
                    {col2: 'Pro'},
                    {col3: 'Popis'}
                ],
                blocks: [
                    {
                        description: 'Soubory cookie používáme k zajištění základních funkcí webu a ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda se chcete dané cookie povolit či zakázat, kdykoli budete chtít.'
                    }, {
                        title: 'Nezbytné a technické cookies',
                        description: 'Tyto soubory cookie jsou nezbytné pro správné fungování našich webových stránek. Bez těchto cookies by web nefungoval správně.',
                        toggle: {
                            value: 'basic',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    /*{
                        title: 'Povolit funkční cookies',
                        description: 'Tyto soubory cookie sbírají data, jejichž účelem je si pamatovat volby uživatelů, abychom mohli poskytnout lepší a více personalizovanou zkušenost.',
                        toggle: {
                            value: 'function',
                            enabled: false,
                            readonly: false         // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },*/
                    {
                        title: 'Povolit analytické cookies',
                        description: 'Tyto soubory cookies nám pomáhají pochopit chování návštěvníků na našem webu, objevit chyby a poskytnout lepší celkovou analytiku.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: '^__utm',       // match all cookies starting with "_ut"
                                col2: 'Google Analytics',
                                col3: 'Více informací o Cookies Google Analytics je <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs_-_cookie_usage" target="_blank">zde</a>',
                                is_regex: true
                            },
                            /*{
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description..',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            },
                            {
                                col1: '^SL_C',
                                col2: 'smartlook.com',
                                col3: '1 month',
                                col4: 'description ...',
                                is_regex: true
                            }*/
                        ]
                    }/*, {
                        title: 'Povolit marketingové cookies',
                        description: 'Tyto soubory cookies jsou použity ke sledování efektivity reklamních kampaní, abychom mohli poskytnout relevantnější služby.',
                        toggle: {
                            value: 'marketing',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_f',       // match all cookies starting with "_ga"
                                col2: 'facebook.com',
                                col3: '3 month',
                                col4: 'description ...',
                                is_regex: true
                            }
                        ]
                    }*/
                ]
            }
        }
    }
});